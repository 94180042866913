<template>
  <div>
    <!------------ START: FieldWrapper ------------>
    <FieldWrapper ref="fieldWrapper" :field="field" :input-group="true">
      <input
        v-model="$v.value.$model"
        class="form-control"
        :class="{ disabled: isDisabled }"
        type="text"
        readonly
        :placeholder="$t('formHelper.selectTime')"
      />
      <!------------ START: Append time icon ------------>
      <div class="input-group-append">
        <span
          class="input-group-text"
          :class="{ 'cursor-pointer': !isDisabled }"
        >
          <i class="fal fa-clock" />
        </span>
      </div>
      <!------------ END: Append time icon ------------>
      <template #hints>
        <!------------ START: Time picker ------------>
        <v-time-picker
          v-if="showPicker"
          ref="picker"
          v-model="$v.value.$model"
          class="position-absolute border zindex-100 shadow"
          v-bind="options"
          @input="onInput"
        >
          <div class="w-100 text-right">
            <button class="btn btn-clean" @click="togglePicker">
              {{ $t("formHelper.ok") }}
            </button>
          </div>
        </v-time-picker>
        <!------------ END: Time picker ------------>
      </template>
    </FieldWrapper>
    <!------------ END: FieldWrapper ------------>
  </div>
</template>

<script>
import FieldWrapper from "@/components/Tools/FormHelper/Components/FieldWrapper";
import { base } from "@/components/Tools/FormHelper/Helper/mixins";
import {formatDateTime} from "@/components/Tools/FormHelper/Helper/functions";

export default {
  components: { FieldWrapper },
  mixins: [base],
  props: {},
  data() {
    return {
      showPicker: false,
      options: {
        format: "24hr",
        scrollable: true,
        min: this.field.min,
        max: this.field.max,
        "use-seconds": this.field.useSeconds ?? false,
        "allowed-hours": this.field.allowedHours,
        "allowed-minutes": this.field.allowedMinutes,
        "allowed-seconds": this.field.allowedSeconds
      }
    };
  },
  computed: {},
  created() {
    window.addEventListener("click", this.onClick);
  },
  mounted() {},
  beforeDestroy() {
    window.removeEventListener("click", this.onClick);
  },
  methods: {
    togglePicker() {
      this.showPicker = !this.showPicker;
    },
    onClick(e) {
      if (this.isDisabled) {
        return;
      }
      // Check if input was clicked
      if (this.$refs.fieldWrapper.$refs.inputGroup.contains(e.target)) {
        this.togglePicker();
        return;
      }
      // Check if picker is already hidden
      if (!this.$refs.picker) {
        return;
      }
      // Check if click was on picker
      this.showPicker = this.$refs.picker.$el.contains(e.target);
    },
    onInput() {
      const payload = formatDateTime(
        this.value,
        this.field.format ?? "HH:mm:ss",
        "HH:mm"
      );
      this.$emit("input", payload);
    }
  }
};
</script>

<style lang="scss">
.zindex-100 {
  z-index: 100;
}
</style>
